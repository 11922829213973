import Link from 'next/link'

export function Link2Video ({ children, slug }) {
  return (
    (
      <Link href={'/content/' + slug} passHref prefetch={false} legacyBehavior>
        {children}
      </Link>
    )
  )
}

export function Link2Show ({ children, slug }) {
  return (
    (
      <Link href={'/sendungen/' + slug} passHref prefetch={false} legacyBehavior>
        {children}
      </Link>
    )
  )
}
export function Link2Station ({ children, slug }) {
  return (
    (
      <Link href={'/' + slug} passHref prefetch={false} legacyBehavior>
        {children}
      </Link>
    )
  )
}

export function Link2Category ({ children, slug }) {
  return (
    (
      <Link href={'/rubriken/' + slug} passHref prefetch={false} legacyBehavior>
        {children}
      </Link>
    )
  )
}
