import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  label: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  button: {
    height: '36px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '36px',
      paddingLeft: '6px',
      paddingRight: '6px'
    }
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0
    }
  }
}))

export default function MenuButton ({ onClick, className }) {
  const classes = useStyles()

  return (
    <>
      <Button
        onClick={onClick}
        color='primary'
        variant='contained'
        startIcon={<Icon>menu</Icon>}
        className={`${classes.button} ${className}`}
        classes={{ startIcon: classes.icon }}
        id='btn-stoebern'
        data-test='button-stoebern'
        aria-label='Stöbern'
      >
        <span className={classes.label}>Menü</span>
      </Button>
    </>
  )
}
