import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import makeStyles from '@mui/styles/makeStyles'
import Toolbar from '@mui/material/Toolbar'
import dynamic from 'next/dynamic'
import React from 'react'
import Logo from './Logo'
import MenuButton from './MenuButton'
import SearchForm from './SearchForm'
import * as gtag from 'lib/gtag'

const DynamicDrawer = dynamic(() => import('./Drawer'), { ssr: false })
const DynamicCalendarSearch = dynamic(() => import('./CalendarSearch'), { ssr: false })

const useStyles = makeStyles((theme) => ({
  appbar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.navbar
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%' // ie11
  },
  logo: {
    flexGrow: 0
  },
  search: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchForm: {
    marginRight: theme.spacing(4),
    '@media (max-width: 430px)': {
      marginRight: theme.spacing(1)
    }
  },
  right: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(3),
    '@media (max-width: 430px)': {
      marginLeft: theme.spacing(1)
    }
  }
}))

export default function Navbar ({ search }) {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [query, setQuery] = React.useState(search || '')

  React.useEffect(() => {
    // set search value if page is called directly
    if (search) setQuery(search)
  }, [search])

  React.useEffect(() => {
    if (drawerOpen) {
      gtag.event('stoebern')
    }
  }, [drawerOpen])

  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setDrawerOpen(!drawerOpen)
  }

  // Closes the drawer
  const handleClose = () => {
    setDrawerOpen(false)
  }

  const handleOpen = () => {
    setDrawerOpen(true)
  }

  const handleCalendarSearch = () => {
    setQuery('')
  }

  return (
    <>
      <AppBar elevation={0} color='inherit' position='fixed' className={classes.appbar}>
        <Container maxWidth='lg'>
          <Toolbar disableGutters className={classes.toolbar}>
            <Logo className={classes.logo} />
            <div className={classes.search}>
              <SearchForm query={query} setQuery={setQuery} className={classes.searchForm} />
              <DynamicCalendarSearch handler={handleCalendarSearch} className={classes.calendar} />
            </div>
            <div className={classes.right}>
              <MenuButton onClick={toggleDrawer} className={classes.menu} />
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <DynamicDrawer
        open={drawerOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  )
}
